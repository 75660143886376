<template>
  <div>
    <a-row>
      <a-col :span="24">
        <a-menu v-model="current" @click="changeMenu" mode="horizontal" class="mb-5">
          <a-menu-item key="cbt">MY CBT</a-menu-item>
          <a-menu-item key="supervision">MY SCHEDULE</a-menu-item>
          <a-menu-item v-if="role.includes('kurikulum')" key="grade">GRADE LOG</a-menu-item>
        </a-menu>
        <template v-if="current.includes('cbt')">
          <div class="d-lg-flex mb-5 mb-lg-2">
            <a-form-item class="mr-lg-3">
              <a-input v-model="search" @keyup="searchCBT" placeholder="Search Mata Pelajaran.."
                style="height: 40px"></a-input>
            </a-form-item>
            <a-form-item class="mr-lg-3">
              <a-select @change="handleSelectType" size="large" style="width: 100%; height: 40px" default-value="All">
                <a-select-option value="All">All Type</a-select-option>
                <a-select-option value="PH">PH</a-select-option>
                <a-select-option value="PTS">PTS</a-select-option>
                <a-select-option value="PAS">PAS</a-select-option>
                <a-select-option value="Others">Others</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="mr-lg-3">
              <a-select @change="handleSelectTahunAjaran" size="large" style="width: 100%; height: 40px"
                :default-value="idActiveYear" :value="selectedTahunAjaran">
                <a-select-option v-for="item in dataTahunAjaran" :key="item.id" :value="item.id">{{ `${item.tahun}
                                  (${item.semester})` }}</a-select-option>
                <!-- <a-select-option value="1">2020 - 2021 (Ganjil)</a-select-option> -->
                <!-- <a-select-option value="2">2020 - 2021 (Genap)</a-select-option> -->
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button @click.prevent="refreshTable" type="default" style="width: 100%; height: 40px">
                <a-icon type="sync" :spin="loadingRefresh" />Refresh
              </a-button>
            </a-form-item>
            <div class="ml-auto">
              <a-button @click.prevent="toAddCbt" type="primary" icon="plus" style="height: 40px" block>Create
                CBT</a-button>
            </div>
          </div>
          <a-table class="table-cbt" :columns="columnsCbt" :data-source="dataTableCbt" :loading="loading"
            :pagination="pagination" @change="handleTableChange">
            <span slot="MataPelajaran" slot-scope="text, record">
              {{ text }}
              <a-tag v-if="record.Status" :color="record.Status === 'publish' ? 'green' : 'orange'">{{
                record.Status === "publish" ? "published" : "draft"
              }}</a-tag>
            </span>
            <a-icon slot="filterIcon" type="search" />
            <a-tag slot="status" v-if="record.isParent" :color="text === 'publish' ? 'green' : 'orange'"
              slot-scope="text, record">{{ text }}</a-tag>
            <span slot="Actions" v-if="record.isParent" slot-scope="text, record">
              <a v-if="role.includes('kurikulum')" :disabled="record.status === 'publish'"
                @click.prevent="toDetailCbt(record.key)" class="btn btn-block px-3" :class="record.status === 'publish'
                  ? 'btn-outline-secondary'
                  : 'btn-outline-primary'
                  " style="min-width: 110px">
                <a-icon type="check-square" />
                &nbsp;{{ record.status === "publish" ? "Approved" : "Approve" }}
              </a>
              <a @click.prevent="toDetailCbt(record.key)" class="btn btn-block px-3 btn-outline-primary">
                <a-icon type="profile" />&nbsp;Detail
              </a>
              <a @click.prevent="toResultCbt(record.key)" class="btn btn-block px-3 btn-outline-primary">
                <a-icon type="crown" />&nbsp;Result
              </a>
            </span>
            <div slot="participants" slot-scope="text, record">
              <div v-if="record.MataPelajaran !== 'PPDB'">
                <template v-if="record.isParent">
                  <!-- <a-tag v-if="record.id_level" color="blue">{{record.level.nama}}</a-tag> -->
                  <!-- <template v-else> -->
                  <a-tag class="m-2" v-for="(kelas, i) in record.children" :key="i" color="orange">{{ kelas.kelas
                  }}</a-tag>
                  <!-- </template> -->
                </template>
                <a-tag v-else color="orange">{{ record.kelas }}</a-tag>
              </div>
              <div v-else>
                <a-tag color="orange">PPDB</a-tag>
              </div>
            </div>
          </a-table>
        </template>
        <template v-if="current.includes('supervision')">
          <!-- <a-button
        v-if="role.includes('kurikulum') && scheduleImage"
        @click="handleDelete"
        :loading="loadingDelete"
        type="danger"
        size="large"
        class="mx-auto"
      >
        <a-icon v-if="!loadingDelete" type="close" />
        {{ loadingDelete ? 'Deleting...' : textDelete }}
      </a-button>
      <img v-if="scheduleImage" :src="url" alt="Al Bayan" width="100%" />
      <a-result
        v-else
        status="404"
        title="It's Empty"
        sub-title="There are no supervision schedule for now"
      >
        <template v-if="role.includes('kurikulum')" #extra>
          <a-upload-dragger :multiple="false" :before-upload="beforeUpload">
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">Click or drag file to this area to upload</p>
            <p class="ant-upload-hint">Support for a image file</p>
          </a-upload-dragger>
          <a-button
            @click="handleUpload"
            :loading="loadingUpload"
            :disabled="newFileStatus"
            type="primary"
            size="large"
            class="mt-4"
          >
            <a-icon v-if="!loadingUpload" type="upload" />
            {{ loadingUpload ? 'Uploading...' : textUpload }}
          </a-button>
        </template>
          </a-result>-->
          <div class="d-lg-flex">
            <a-form-item class="mr-lg-3">
              <a-input v-model="search" @keyup="searchCBT" placeholder="Search Mata Pelajaran.."
                style="height: 40px"></a-input>
            </a-form-item>
            <a-form-item class="mr-lg-3">
              <a-select @change="handleSelectType" size="large" style="width: 100%; height: 40px" default-value="All">
                <a-select-option value="All">All Type</a-select-option>
                <a-select-option value="PH">PH</a-select-option>
                <a-select-option value="PTS">PTS</a-select-option>
                <a-select-option value="PAS">PAS</a-select-option>
                <a-select-option value="Others">Others</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button @click.prevent="refreshTable" type="default" style="width: 100%; height: 40px">
                <a-icon type="sync" :spin="loadingRefresh" />Refresh
              </a-button>
            </a-form-item>
          </div>
          <a-table class="table-cbt" :columns="columnsSchedule" :data-source="dataTableSchedule" :loading="loading"
            :pagination="paginationSchedule" @change="handleTableChange" bordered>
            <span slot="Actions" slot-scope="text, record">
              <!-- {{record}} -->
              <template v-if="record.isNow">
                <a @click.prevent="toSupervisionCbt(record.key)" class="btn btn-outline-primary mx-2">
                  <a-icon type="audit" />&nbsp;Supervision
                </a>
              </template>
              <template v-else>
                <p v-if="record.isUndone">
                  <a-icon type="hourglass" />
                  {{ findDayDifferenceUndone(record.examStart) }}
                </p>
                <a v-else disabled class="btn btn-info mx-2">Closed</a>
              </template>
              <!-- <a @click.prevent="toSupervisionCbt(record.key)" class="btn btn-outline-primary mx-2">
            <a-icon type="audit" />&nbsp;Supervision
              </a>-->
            </span>
          </a-table>
        </template>
        <template v-if="current.includes('grade')">
          <grade-log />
        </template>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
import Axios from 'axios'
import config from '@/config'
const GradeLog = () => import('@/components/app/GradeLog')
const columnsCbt = [
  {
    title: 'Mata Pelajaran',
    dataIndex: 'MataPelajaran',
    // width: '20%',
    scopedSlots: { customRender: 'MataPelajaran' },
    width: 200,
    sorter: true,
  },
  {
    title: 'Participants',
    // width: '8%',
    scopedSlots: { customRender: 'participants' },
    // sorter: true,
    align: 'center',
    width: '200px',
  },
  {
    title: 'Type',
    dataIndex: 'Type',
    // width: '8%',
    scopedSlots: { customRender: 'Type' },
    sorter: true,
    align: 'center',
  },
  {
    title: 'Duration (minutes)',
    dataIndex: 'Duration',
    // width: '11%',
    scopedSlots: { customRender: 'Duration' },
    // sorter: true,
    align: 'center',
  },
  {
    title: 'StartAt',
    dataIndex: 'StartAt',
    // width: '10%',
    scopedSlots: { customRender: 'StartAt' },
    sorter: true,
    align: 'center',
  },
  {
    title: 'EndAt',
    dataIndex: 'EndAt',
    // width: '10%',
    scopedSlots: { customRender: 'EndAt' },
    sorter: true,
    align: 'center',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    // width: '11%',
    scopedSlots: { customRender: 'status' },
    align: 'center',
  },
  {
    title: 'Actions',
    dataIndex: 'Actions',
    // width: '40%',
    align: 'center',
    scopedSlots: { customRender: 'Actions' },
  },
]
const columnsSchedule = [
  {
    title: 'Mata Pelajaran',
    dataIndex: 'MataPelajaran',
    // width: '20%',
    // width: 200,
    scopedSlots: { customRender: 'MataPelajaran' },
    sorter: true,
  },
  {
    title: 'Class',
    dataIndex: 'Class',
    // width: '8%',
    // width: 50,
    scopedSlots: { customRender: 'Class' },
    sorter: true,
  },
  {
    title: 'Type',
    dataIndex: 'Type',
    // width: '8%',
    // width: 50,
    scopedSlots: { customRender: 'Type' },
    sorter: true,
  },
  {
    title: 'Duration',
    dataIndex: 'Duration',
    // width: '11%',
    // width: 50,
    scopedSlots: { customRender: 'Duration' },
    sorter: true,
  },
  {
    title: 'StartAt',
    dataIndex: 'StartAt',
    // width: '10%',
    // width: 20,
    scopedSlots: { customRender: 'StartAt' },
    sorter: true,
  },
  {
    title: 'EndAt',
    dataIndex: 'EndAt',
    // width: '10%',
    // width: 20,
    scopedSlots: { customRender: 'EndAt' },
    sorter: true,
  },
  {
    title: 'Actions',
    dataIndex: 'Actions',
    // width: 200,
    scopedSlots: { customRender: 'Actions' },
    align: 'center',
  },
]

export default {
  components: {
    GradeLog,
  },
  data() {
    return {
      current: ['cbt'],
      search: '',
      dataTableCbt: [],
      dataTableSchedule: [],
      columnsCbt,
      columnsSchedule,
      pagination: { current: 1 },
      paginationSchedule: {},
      newFileStatus: true,
      newFile: {},
      headers: {
        'Access-Control-Allow-Origin': '*',
        token: localStorage.token,
      },
      classes: [],
      selectedType: '',
      selectedTahunAjaran: '',
      dataTahunAjaran: [],
      idActiveYear: null,
      url: '',
      scheduleImage: '',
      textUpload: 'Upload Supervision Schedule',
      textDelete: 'Remove Supervision Schedule',
      loading: false,
      loadingRefresh: false,
      loadingUpload: false,
      loadingDelete: false,
      order: 'DESC',
      sortBy: 'id',
    }
  },
  methods: {
    toAddCbt() {
      this.$router.push({ name: 'Add Cbt Teacher' })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Cbt Teacher'],
      })
    },
    toDetailCbt(id) {
      this.$router.push({ name: 'Detail Cbt Teacher', params: { id } })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Cbt Teacher'],
      })
    },
    toResultCbt(id) {
      this.$router.push({ name: 'Result Cbt Teacher', params: { id } })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Cbt Teacher'],
      })
    },
    toSupervisionCbt(id) {
      this.$router.push({ name: 'Cbt Supervision Teacher', params: { id } })
      this.$store.commit('menu/SET_STATE', {
        currentMenu: ['Cbt Teacher'],
      })
    },
    searchCBT() {
      if (this.current.includes('cbt')) {
        this.fetchData()
      } else {
        this.fetchDataSchedule()
      }
    },
    findDayDifferenceUndone(date) {
      if (moment().diff(moment(date, 'YYYY-DD-MM HH:mm'), 'days') === 0) {
        return `${moment(date, 'YYYY-DD-MM HH:mm').diff(moment(), 'hours')} hours to go`
      } else {
        const diffDay = moment(date, 'YYYY-DD-MM HH:mm').diff(moment(), 'days')
        return `${diffDay} day${diffDay === 1 ? '' : 's'} to go`
      }
    },
    supervisionUpload() {
      // console.log('ok')
    },
    beforeUpload(file) {
      this.newFileStatus = false
      this.newFile = file
      return false
    },
    handleUpload() {
      const info = this.newFile
      const type = info.type
      // console.log(info)
      this.loadingUpload = true
      if (type === 'image/png' || type === 'image/jpeg') {
        const formData = new FormData()
        formData.append('supervision', info)
        Axios.post(`${config.apiUrl}/api/cbt/supervision/upload-schedule`, formData, {
          headers: {
            token: localStorage.token,
          },
        })
          .then(res => {
            if (res.data.message === 'SUCCESS_UPLOAD_SUPERVISION') {
              this.fetchDataSupervision()
              this.$notification.success({
                message: 'Success.',
                description: 'Upload Success.',
              })
            } else {
              this.$notification.error({
                message: 'Sorry.',
                description: 'Upload Failed.',
              })
            }
            this.loadingUpload = false
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.newFile = {}
        this.loadingUpload = false
        this.$notification.error({
          message: 'Sorry.',
          description: 'Only image are allowed.',
        })
      }
    },
    handleDelete() {
      this.loadingDelete = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure you want to cancel editing ? your changes will be lost</div>
        ),
        onOk: () => {
          this.loadingDelete = false
          this.$store.dispatch('cbt/REMOVE_SUPERVISION')
            .then(res => {
              this.scheduleImage = ''
              this.url = ''
              this.newFile = {}
              this.newFileStatus = true
              if (res === 'SUCCESS_UNLINK_SUPERVISION') {
                this.$notification.success({
                  message: 'Success.',
                  description: 'Supervision schedule has been deleted.',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDelete = false
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Yes',
      })
    },
    handleSelectType(value) {
      if (value === 'All') {
        value = ''
      }
      this.selectedType = value
      if (this.current.includes('cbt')) {
        this.fetchData()
      } else {
        this.fetchDataSchedule()
      }
    },
    handleSelectTahunAjaran(value) {
      this.selectedTahunAjaran = value
      if (this.current.includes('cbt')) {
        this.fetchData()
      } else {
        this.fetchDataSchedule()
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      // console.log('PAGE =>' + this.pagination.current)
      if (sorter.field === 'MataPelajaran') {
        this.sortBy = 'nama'
      } else if (sorter.field === 'Duration') {
        this.sortBy = 'durasi'
      } else if (sorter.field === 'Type') {
        this.sortBy = 'tipe'
      } else if (sorter.field === 'StartAt') {
        this.sortBy = 'waktu_ujian_dibuka'
      } else if (sorter.field === 'EndAt') {
        this.sortBy = 'waktu_ujian_ditutup'
      }
      if (sorter.order === 'ascend') {
        this.order = 'ASC'
      } else {
        this.order = 'DESC'
      }
      if (this.current.includes('cbt')) {
        this.fetchData()
      } else {
        this.fetchDataSchedule()
      }
    },
    refreshTable() {
      if (this.current.includes('cbt')) {
        this.fetchData()
      } else {
        this.fetchDataSchedule()
      }
    },
    async fetchData() {
      try {
        this.loading = true
        const res = await this.$store.dispatch('cbt/FETCH_CBT_TEACHER', { page: this.pagination.current, order: this.order, tipe: this.selectedType, search: this.search, sortBy: this.sortBy, idTahunAjaran: this.selectedTahunAjaran })
        this.loading = false
        // console.log(res, 'aye')
        const pagination = { ...this.pagination }
        pagination.total = res.total
        this.pagination = pagination
        // console.log(res.cbts)
        this.dataTableCbt = res.cbts.map(row => {
          const examStart = moment(row.waktu_ujian_dibuka)
          const examEnd = moment(row.waktu_ujian_ditutup)
          let children = null
          // const children = null
          // if (!['PTS', 'PAS'].includes(row.tipe)) {
          const dataPengawas = []
          row.pengawas_cbts.forEach(pengawas => {
            const isExist = dataPengawas.find(dat => dat.kelasId === pengawas.kelasId)
            if (!isExist) dataPengawas.push(pengawas)
          })
          children = dataPengawas.map(pengawas => {
            return {
              key: `tree-${pengawas.id}`,
              kelas: row.mata_pelajaran.nama === 'PPDB' ? 'PPDB' : `${pengawas.kela.level.nama} - ${pengawas.kela.simbol}`,
              StartAt: pengawas.waktu_ujian_dibuka ? moment(pengawas.waktu_ujian_dibuka).format('DD MMM YYYY HH:mm') : moment(row.waktu_ujian_dibuka).format('DD MMM YYYY HH:mm') || null,
              EndAt: pengawas.waktu_ujian_ditutup ? moment(pengawas.waktu_ujian_ditutup).format('DD MMM YYYY HH:mm') : moment(row.waktu_ujian_ditutup).format('DD MMM YYYY HH:mm') || null,
            }
          })
          // }
          return {
            ...row,
            key: row.id,
            MataPelajaran: row.mata_pelajaran.nama,
            Level: row.tingkat,
            Type: row.tipe,
            Duration: row.durasi,
            status: row.status,
            StartAt: children?.length ? null : moment(row.waktu_ujian_dibuka).format('DD MMM YYYY HH:mm'),
            EndAt: children?.length ? null : moment(row.waktu_ujian_ditutup).format('DD MMM YYYY HH:mm'),
            TahunAjaran: `${row.tahun_ajaran.tahun} (${row.tahun_ajaran.semester})`,
            isUndone: moment(examEnd, 'YYYY-DD-MM HH:mm').isAfter(moment()),
            isNow: moment().isBetween(moment(examStart, 'YYYY-DD-MM HH:mm'), moment(examEnd, 'YYYY-DD-MM HH:mm'), 'miliseconds', '[)'),
            examStart,
            examEnd,
            children,
            isParent: true,
          }
        })
        // console.log(this.dataTableCbt)
        // console.log(this.dataTableCbt)
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchDataSchedule() {
      try {
        this.loading = true
        const res = await this.$store.dispatch('cbt/FETCH_CBT_SCHEDULE_TEACHER', { page: this.pagination.current, order: this.order, tipe: this.selectedType, search: this.search, sortBy: this.sortBy })
        this.loading = false
        const pagination = { ...this.paginationSchedule }
        pagination.total = res.total
        this.paginationSchedule = pagination
        // console.log(res)
        this.dataTableSchedule = res.cbts.map(row => {
          const examStart = moment(row.waktu_ujian_dibuka)
          const examEnd = moment(row.waktu_ujian_ditutup)
          return {
            key: row.id,
            MataPelajaran: row.mata_pelajaran,
            Class: row.kelas,
            ClassId: row.id_kelas,
            Type: row.tipe,
            Duration: row.durasi,
            StartAt: moment(row.waktu_ujian_dibuka).format('DD MMM YYYY HH:mm'),
            EndAt: moment(row.waktu_ujian_ditutup).format('DD MMM YYYY HH:mm'),
            isUndone: moment(examEnd, 'YYYY-DD-MM HH:mm').isAfter(moment()),
            isNow: moment().isBetween(moment(examStart, 'YYYY-DD-MM HH:mm'), moment(examEnd, 'YYYY-DD-MM HH:mm'), 'miliseconds', '[)'),
            examStart,
            examEnd,
          }
        })
        // console.log(this.dataTableCbt)
        // console.log(this.dataTableCbt)
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    fetchDataKelas() {
      this.$store.dispatch('admin/FETCH_CLASS')
        .then(data => {
          this.classes = data
        })
    },
    fetchDataSupervision() {
      this.$store.dispatch('cbt/FETCH_SUPERVISION')
        .then(res => {
          if (res.data.length) {
            this.scheduleImage = res.data[0]
            this.url = `${config.apiUrl}/${this.scheduleImage.filepath}`
            // console.log(this.url)
          }
        })
    },
    async fetchDataTahunAjaran() {
      try {
        const res = await this.$store.dispatch('cbt/FETCH_TAHUN_AJARAN')
        this.dataTahunAjaran = res.listTA
        this.idActiveYear = res.activeYear.id
        this.selectedTahunAjaran = res.activeYear.id
        return new Promise((resolve) => resolve())
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    changeMenu({ key }) {
      if (key === 'cbt') this.fetchData()
      else if (key === 'supervision') this.fetchDataSchedule()
    },
  },
  async created() {
    try {
      await this.fetchDataTahunAjaran()
      this.fetchData()
      this.fetchDataSchedule()
      this.fetchDataKelas()
    } catch (err) {
      console.log(err)
    }
    // this.fetchDataSupervision()
  },
  computed: {
    user() { return this.$store.state.user.user },
    role() {
      return this.$store.state.user.role
    },
  },
}
</script>

<style lang="scss">
.table-cbt {
  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;
  }
}
</style>
